import * as React from "react"
import Layout from "../components/layout/index"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const NotFoundPage = ({ location }) => {
  return (
    <Layout path={location.pathname}>
      <Seo title="关于我们" />
      <div>
        <div>
          <h4>HJGP技术知识库</h4>
          <p>“全球水果链，共享幸福果”</p>
          <StaticImage
            layout="constrained"
            formats={["auto", "webp", "avif"]}
            src="../images/about.jpg"
            width={1200}
            height={400}
            quality={95}
            alt="Profile picture"
          />
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
